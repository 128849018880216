<template>
  <v-app>
    <navbar />

    <login-dialog
      v-if="showLoginDialog"
      :expired="true" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import LoginDialog from '@/components/LoginDialog'

export default {
  name: 'App',
  components: {
    Navbar,
    LoginDialog
  },
  computed: {
    showLoginDialog () {
      return this.$store.getters.loginDialog
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  padding: 0;
  height: 12px;
  width: 12px;
  overflow: visible;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: solid transparent;
  background-color: #a8a8a8;
  border-width: 3px;
  border-radius: 50px;

  &:hover {
    border-width: 1px;
  }
}

body {
  margin: 0 !important;
}

html,
body,
.v-application,
.v-main {
  height: 100%;
}

body.swal2-shown {
  height: 100% !important;
  overflow: unset !important;
}

.fill-height {
  height: 100%;
}

.fill-width {
  width: 100%;
}

.loader-overlay {
  .v-overlay__content {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .dialog-title {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2rem;
  }
}
</style>
