import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/LoginDialog')
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('@/views/Setup')
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: () => import('@/views/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const actk = localStorage.getItem('actk')

  const isLoggedIn = actk != null && actk !== ''

  if (to.meta.requiresAuth === true && !isLoggedIn) {
    next({ path: '/login', query: { redirect: to.fullPath }, replace: true })
    return
  }

  to.path === '/login' && isLoggedIn ? next('/') : next()
})

export default router
