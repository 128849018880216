export const rules = {
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
      startLetter: value => (value && /^[a-zA-Z]/i.test(value)) || 'Must start with a letter',
      alphanumericUndersore: value => (value && /^[a-zA-Z0-9_]+$/i.test(value)) || 'Letters, numbers and underscores only',
      usernameLength: value => (value && value.length >= 3 && value.length <= 20) || 'Username must be between 3 and 20 characters',
      matchPass: (value, value2) => (value && value2 && value === value2) || 'Passwords do not match'
    }
  })
}
