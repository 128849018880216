<template>
  <v-app-bar
    app
    color="primary"
    dense
    dark>
    <v-btn
      v-if="showHomeButton"
      to="/"
      icon>
      <v-icon title="Home">
        $home
      </v-icon>
    </v-btn>

    <v-toolbar-title class="mx-auto">
      {{ pageTitle }}
    </v-toolbar-title>

    <v-btn
      v-if="showLogoutButton"
      icon
      @click="logOut">
      <v-icon title="Logout">
        $logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({

  }),
  computed: {
    pageTitle () {
      return this.$route.name
    },
    showHomeButton () {
      return this.$route.path !== '/' && this.$route.path !== '/login'
    },
    showLogoutButton () {
      return this.$route.meta.requiresAuth
    }
  },
  methods: {
    goHome () {
      this.$router.push('/').catch(() => { })
    },
    logOut () {
      localStorage.removeItem('actk')

      this.$router.push('/login').catch(() => { })
    }
  }
}
</script>
