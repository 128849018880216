import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import {
  mdiHome as home,
  mdiLogout as logout,
  mdiCog as cog,
  mdiDotsVertical as vertMenu,
  mdiRefresh as refresh,
  mdiDeleteForever as bin,
  mdiDownload as download
} from '@mdi/js'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      home,
      logout,
      cog,
      vertMenu,
      refresh,
      bin,
      download
    }
  }
})
