import axios from 'axios'

export default {
  methods: {
    processRequestError (error, returnResponse = false, silent = false) {
      let sessionExpired = false

      if (error.response?.data?.code === 22) {
        sessionExpired = true
        this.$store.commit('toggleLoginDialog', true)
      }

      const actk = localStorage.getItem('actk')

      const isLoggedIn = actk != null && actk !== ''

      if (error.response?.status === 401 && !isLoggedIn && ['/login', '/setup'].indexOf(this.$route.path) === -1) {
        sessionExpired = true
        this.$store.commit('toggleLoginDialog', true)
      }

      const errorMessage = axios.isCancel(error) ? null : error.response?.data?.message ?? error.response?.data ?? error.message
      const errorCode = error.response?.data?.code ?? null

      if (returnResponse) {
        return { code: errorCode, message: errorMessage, sessionExpired }
      }

      if (sessionExpired) {
        return
      }

      if (silent) {
        console.error(error)
        return
      }

      this.$swal.fire('Error', errorMessage, 'error')
    }
  }
}
