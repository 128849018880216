import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import swal from 'sweetalert2'

Vue.config.productionTip = false

const swalDefault = swal.mixin({
  backdrop: true,
  allowEnterKey: false,
  confirmButtonColor: '#1976D2',
  cancelButtonColor: '#FF5252'
})

Vue.prototype.$swal = swalDefault

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
